@font-face {
    font-face-name: Oswald-Regular;
    src: url("./assets/Oswald-Regular.ttf");
}

.main-container {
    text-align: center;
    font-family: Oswald-Regular, serif;
    color: #fc8fca;
}

.nav-strip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 94.6vw;
    margin: 1.2vh 2.7vw;
}

.nav-logo {
    border: solid #fc8fca .33vh;
    border-radius: 50%;
    width: 6vw;
}

.nav-link {
    text-decoration: none;
    color: #fc8fca;
}

.nav-text {
    font-size: 4.2vh;
}

.title-container {
    height: 8.1vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 6.9vh;
    animation: title-animation 2.7s infinite alternate;
}

@keyframes title-animation {
    from {
        font-size: 6.9vh;
        padding-top: 0;
    }
    to {
        font-size: 7.5vh;
        padding-top: 2.7vh;
    }
}

.body-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 84vh;
    width: 94.6vw;
    margin: 4.5vh 2.7vw 30vh;
}

.meme-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
    height: 100%;
    border-right: solid #fc8fca 0.33vh;
    margin-top: 15vh;
}

.image-container {
    height: 25vw;
    width: 25vw;
}

.image-border {
    height: 25vw;
    width: 25vw;
    border: solid #fc8fca 0.66vh;
    border-radius: 1.2vh;
}

.image {
    height: 25vw;
    width: 25vw;
    position: absolute;
    margin-left: -12.5vw;
}

.layer-one {
    z-index: 0;
}

.layer-two {
    z-index: 1;
}

.layer-three {
    z-index: 2;
}

.layer-four {
    z-index: 3;
}

.layer-five {
    z-index: 4;
}

.button-one {
    height: 7.5vh;
    width: 26vw;
    border-radius: 1.5vh;
    border: solid #fc8fca 0.66vh;
    background-color: #f7e9f8;
    color: #fc8fca;
    font-size: 3vh;
    font-family: Oswald-Regular, serif;
}

.button-one:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.button-two {
    height: 7.5vh;
    width: 26vw;
    border-radius: 1.5vh;
    border: solid #fc8fca 0.66vh;
    background-color: #fc8fca;
    color: white;
    font-size: 3vh;
    font-family: Oswald-Regular, serif;
}

.button-two:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.traits-container {
    width: 50vw;
    margin-top: 3vh;
    margin-left: 4.5vw;
}

.scroll-button {
    margin: 0 0.66vw;
    border-radius: 1.2vh;
    border: solid #fc8fca 0.33vh;
    background-color: #f7e9f8;
    color: #fc8fca;
    font-size: 3vh;
}

.scroll-button:hover {
    cursor: pointer;
}

.scroll-panel {
    margin: 3vh;
    display: flex;
    flex-direction: row;
}

.trait-image-container {
    display: flex;
    overflow-x: auto;
    max-width: 38vw;
}

.trait-image-container::-webkit-scrollbar,
.trait-image-container::-webkit-scrollbar-button {
    display: none;
}

.trait-image-border {
    height: 6vw;
    width: 6vw;
    margin: 0 0.66vw;
    border-radius: 1.2vh;
}

.trait-image-border-selected {
    border: solid #fc8fca 0.33vh;
    opacity: 100%;
}

.trait-image {
    height: 6vw;
    width: 6vw;
    opacity: 33%;
}

.trait-image:hover {
    cursor: pointer;
}

.trait-image-selected {
    opacity: 100%;
}
