@font-face {
    font-face-name: Oswald-Regular;
    src: url("./assets/Oswald-Regular.ttf");
}

.main-container-mobile {
    text-align: center;
    font-family: Oswald-Regular, serif;
    color: #fc8fca;
}

.nav-strip-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 2.7vw;
}

.nav-logo-mobile {
    border: solid #fc8fca .33vh;
    border-radius: 50%;
    width: 18vw;
}

.nav-link-mobile {
    text-decoration: none;
    color: #fc8fca;
}

.nav-text-mobile {
    font-size: 3.3vh;
}

.title-container-mobile {
    height: 21vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-mobile {
    font-size: 6vh;
    animation: title-animation-mobile 2.7s infinite alternate;
}

@keyframes title-animation-mobile {
    from {
        font-size: 5.2vh;
        padding-top: 0;
    }
    to {
        font-size: 6vh;
        padding-top: 2.7vh;
    }
}

.body-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 175vh;
    margin: 4.5vh 2.7vw 15vh;
}

.meme-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.image-container-mobile {
    height: 95vw;
    width: 95vw;
}

.image-border-mobile {
    height: 95vw;
    width: 95vw;
    border: solid #fc8fca 0.66vh;
    border-radius: 1.5vh;
}

.image-mobile {
    height: 95vw;
    width: 95vw;
    position: absolute;
    margin-left: -48vw;
}

.layer-one-mobile {
    z-index: 0;
}

.layer-two-mobile {
    z-index: 1;
}

.layer-three-mobile {
    z-index: 2;
}

.layer-four-mobile {
    z-index: 3;
}

.layer-five-mobile {
    z-index: 4;
}

.button-one-mobile {
    height: 7.5vh;
    width: 95vw;
    border-radius: 1.5vh;
    border: solid #fc8fca 0.66vh;
    background-color: #f7e9f8;
    color: #fc8fca;
    font-size: 3vh;
    font-family: Oswald-Regular, serif;
}

.button-one-mobile:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.button-two-mobile {
    height: 7.5vh;
    width: 95vw;
    border-radius: 1.5vh;
    border: solid #fc8fca 0.66vh;
    background-color: #fc8fca;
    color: white;
    font-size: 3vh;
    font-family: Oswald-Regular, serif;
}

.button-two-mobile:hover {
    cursor: pointer;
    transform: scale(0.96);
}

.traits-container-mobile {
    margin-top: 3vh;
}

.scroll-button-mobile {
    width: 12vw;
    height: 9.5vh;
    margin: 0 0.66vw;
    border-radius: 1.2vh;
    border: solid #fc8fca 0.33vh;
    background-color: #f7e9f8;
    color: #2f0a1e;
    font-size: 2.1vh;
}

.scroll-button-mobile:hover {
    cursor: pointer;
}

.scroll-panel-mobile {
    margin: 3vh;
    display: flex;
    flex-direction: row;
}

.trait-image-container-mobile {
    display: flex;
    overflow-x: auto;
    max-width: 58vw;
}

.trait-image-container-mobile::-webkit-scrollbar,
.trait-image-container-mobile::-webkit-scrollbar-button {
    display: none;
}

.trait-image-border-mobile {
    height: 9vh;
    width: 9vh;
    margin: 0 0.66vw;
    border-radius: 1.2vh;
}

.trait-image-border-selected-mobile {
    border: solid #fc8fca 0.33vh;
    opacity: 100%;
}

.trait-image-mobile {
    height: 9vh;
    width: 9vh;
    opacity: 66%;
}

.trait-image-mobile:hover {
    cursor: pointer;
}

.trait-image-selected-mobile {
    opacity: 100%;
}
